<template>
  <input type="text" class="timepicker" v-model="schedule" />
</template>

<script>
export default {
  props: {
    taskKey: String,
    schedule: String,
  },
  mounted() {
    const taskKey = this.taskKey
    $(this.$el).timepicker({
      timeFormat: 'h:mm p',
      interval: 15,
      dynamic: true,
      dropdown: true,
      scrollbar: true,
      change: function (time) {
        var schedule = moment(time).format('LT')
        $.ajax({
          url: '/tasks/check_compliances/' + taskKey,
          type: 'PUT',
          dataType: 'json',
          data: {
            task: {
              schedule,
            },
          },
          success: function (data, textStatus, jqXHR) {},
          sync: false,
        })
      },
    })
  },
}
</script>

<style scoped>
input {
  margin-bottom: 0;
}
</style>
