<template>
  <div>
    <h5
      class="darker-bg white-text sectionHeading flex-box flex-vertical-center-align justify-content-space-between mb0"
    >
      &nbsp;&nbsp;Check Compliance Scheduling
    </h5>
    <table class="list-table bordered inside-table mb20">
      <thead>
        <tr>
          <th width="80%">Client</th>
          <th width="10"></th>
          <th></th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="client in clients" :key="client.id">
          <tr>
            <td @click="expand(client)" class="client-name" :class="{ active: client.expanded }" colspan="2">
              {{ client.name }}
            </td>
            <td v-if="!client.expanded" class="arrow">→</td>
            <td v-if="client.expanded" class="arrow">↓</td>
            <td>
              <Toggle v-model="client.task.active" offLabel="Off" onLabel="On" :disabled="true" />
            </td>
          </tr>
          <tr class="hide" :class="{ open: client.expanded }">
            <td colspan="4">
              <table class="list-table no-border">
                <thead>
                  <tr>
                    <th class="text-left" width="30%">Portfolio</th>
                    <th class="text-left" width="25%">Queue time</th>
                    <th class="text-left" width="25%">Finished time</th>
                    <th class="text-left" width="10%">Schedule</th>
                    <th width="10%">Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="portfolio in client.portfolios" :key="portfolio.id">
                    <td width="30%">
                      <div>{{ portfolio.name }}</div>
                    </td>
                    <td>
                      <div v-if="portfolio?.task?.enqueued_at" class="status enqueued">
                        {{ portfolio.task.enqueued_at }}
                      </div>
                    </td>
                    <td>
                      <div v-if="portfolio?.task?.processed_at" class="status processed">
                        {{ portfolio.task.processed_at }}
                      </div>
                    </td>
                    <td>
                      <TimePicker
                        :schedule="portfolio.task.schedule"
                        :taskKey="`check-compliance-portfolio-${portfolio.id}`"
                      ></TimePicker>
                    </td>
                    <td>
                      <Toggle
                        v-model="portfolio.task.active"
                        offLabel="Off"
                        onLabel="On"
                        @change="activatePortfolio(client, portfolio)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import Toggle from '@vueform/toggle'
import TimePicker from '@/views/shared/time-picker.vue'

export default {
  components: {
    Toggle,
    TimePicker,
  },
  setup() {},

  data() {
    return {
      clients: [],
    }
  },

  methods: {
    expand(client) {
      this.clients.filter((_client) => _client != client).forEach((client) => (client.expanded = false))
      client.expanded = !client.expanded
    },

    activatePortfolio(client, portfolio) {
      const schedule = client.task.schedule
      const checked = portfolio.task.active
      const key = `check-compliance-portfolio-${portfolio.id}`
      const portfolio_id = portfolio.id

      $.ajax({
        url: '/tasks/check_compliances/' + key,
        type: 'PUT',
        dataType: 'json',
        data: {
          task: {
            active: checked,
            schedule,
            arguments: {
              portfolio_id,
            },
          },
        },
        success: function (data, textStatus, jqXHR) {},
        sync: false,
      })

      client.task.active = true
      if (!client.portfolios.find((portfolio) => portfolio.task.active)) {
        client.task.active = false
      }
    },
  },

  mounted() {
    this.axios.get('/api/clients').then((response) => {
      this.clients = response.data

      this.clients.forEach((client) => {
        client.task.active = true
        if (!client.portfolios.find((portfolio) => portfolio.task.active)) {
          client.task.active = false
        }
      })
    })
  },
}
</script>

<style scoped lang="scss">
tr.open {
  display: table-row !important;
}
.client-name {
  border-right: 0;
  cursor: pointer;
  &.active {
    background-color: #00b29c;
    color: #ffffff;
    font-weight: bold;

    & ~ td {
      background-color: #00b29c;
      color: #ffffff;
    }
  }
}
.arrow {
  font-size: 1.5rem;
}
.status {
  margin-left: 1rem;
  &.processed:before {
    background-color: #94e185;
    border-color: #78d965;
    box-shadow: 0px 0px 4px 1px #94e185;
  }

  &.enqueued:before {
    background-color: #ffc182;
    border-color: #ffb161;
    box-shadow: 0px 0px 4px 1px #ffc182;
  }

  &.dead:before {
    background-color: #c9404d;
    border-color: #c42c3b;
    box-shadow: 0px 0px 4px 1px #c9404d;
  }

  &:before {
    content: ' ';
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-right: 10px;
    border: 1px solid #000;
    border-radius: 7px;
  }
}
.list-table .open > td {
  padding: 0;

  tbody td {
    background: #eeeeee;
  }
}
</style>

<style src="@vueform/toggle/themes/default.css"></style>
