import { createWebHistory, createWebHashHistory, createRouter } from 'vue-router'

import CheckCompliances from '@/views/tasks/check_compliances/index.vue';
import Blank from '@/views/shared/blank.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/tasks/check_compliances', component: CheckCompliances },
    { path: '/:catchAll(.*)', component: Blank }
  ]
});

export default router;