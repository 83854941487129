import { createApp } from 'vue/dist/vue.esm-bundler'

import Layout from '@/views/shared/layout.vue'
import Router from '@/routes.js'

import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(Layout)
app.use(Router).use(VueAxios, axios).mount('#vueapp')
